import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/LayoutMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Contact me`}</h1>
    <p>{`You can get in toucn with me`}</p>
    <h2>{`Contact me again`}</h2>
    <p>{`If the failing component(s) is a regular component and not intended to be a page
component, you generally want to use a (`}<a parentName="p" {...{
        "href": "https://gatsbyjs.org/docs/static-query"
      }}>{`https://gatsbyjs.org/docs/static-query`}</a>{`)
instead of exporting a page query.`}</p>
    <p>{`If you're more experienced with GraphQL, you can also export GraphQL
fragments from components and compose the fragments in the Page component`}</p>
    <p>{`If the failing component(s) is a regular component and not intended to be a page
component, you generally want to use a (`}<a parentName="p" {...{
        "href": "https://gatsbyjs.org/docs/static-query"
      }}>{`https://gatsbyjs.org/docs/static-query`}</a>{`)
instead of exporting a page query.`}</p>
    <p>{`If you're more experienced with GraphQL, you can also export GraphQL
fragments from components and compose the fragments in the Page component`}</p>
    <p>{`If the failing component(s) is a regular component and not intended to be a page
component, you generally want to use a (`}<a parentName="p" {...{
        "href": "https://gatsbyjs.org/docs/static-query"
      }}>{`https://gatsbyjs.org/docs/static-query`}</a>{`)
instead of exporting a page query.`}</p>
    <p>{`If you're more experienced with GraphQL, you can also export GraphQL
fragments from components and compose the fragments in the Page component`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      