/** @jsx jsx */
import { Global } from "@emotion/core"
import { Fragment } from "react"
import Helmet from "react-helmet"
import { jsx } from "theme-ui"
import useSiteMetadata from "../hooks/useSiteMetadata"
import Banner from "./Banner"
import NavBar from "./NavBar"

const LayoutMdx = ({ children }) => {
  const { title, description } = useSiteMetadata()

  return (
    <Fragment>
      <Global
        styles={theme => ({
          "*": {
            boxSizing: "border-box",
          },
          body: {
            fontFamily: theme.fonts.body,
            fontsize: 21,
            margin: 0,
          },
        })}
      />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <NavBar />

      <Banner>
        <main sx={{ my: 4, mx: "auto", maxWidth: 650, width: "90vw" }}>
          {children}
        </main>
      </Banner>
    </Fragment>
  )
}

export default LayoutMdx
